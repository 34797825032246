<template>
  <ActionModal
    v-if="isCardModalShown"
    :width="'600px'"
    :height="'260px'"
    :isModalShown="isCardModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Add new card</div>
      <div class="close-modal-btn" @click="$emit('hideCardModal')"></div>
    </div>
    <div class="error-container">{{errorMessage}}</div>
    <div id="card-element">
      <!-- stripe UI will be loaded here -->
    </div>
  <ActionButton
    :width="'calc(100% - 80px)'"
    :height="'50px'"
    :minHeight="'30px'"
    :text="'Add card'"
    @click="addNewCard"
  />
  </ActionModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ActionModal from '../../common/ActionModal.vue'
import ActionButton from '../../common/ActionButton.vue'

export default {
  props: ['isCardModalShown'],
  data() {
    return {
      isModalLoading: false,
      stripe: null,
      elements: null,
      card: null,
      errorMessage: '',
    }
  },
  components: {
    ActionModal,
    ActionButton,
  },
  async created() {
    await this.connectStripe()
  },
  watch: {
    isCardModalShown(newValue, _) {
      if (newValue) {
        setTimeout(() => this.card.mount('#card-element'))
      } else {
        this.errorMessage = ''
      }
    },
  },
  computed: {
    ...mapState([
      'cardsList',
    ])  
  },
  methods: {
    ...mapActions([
      'addCard',
      'setCardAsDefault',
    ]),
    handleStripeErrors(error) {
      if (error) {
        this.errorMessage = error.message
      } else {
        this.errorMessage = ''
      }
    },
    async connectStripe() {
      this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
      this.elements = this.stripe.elements()

      const cardStyle = {
        base: {
          color: "black",
          fontFamily: 'Rubik, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "gray"
          }
        },
        invalid: {
          fontFamily: 'Rubik, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }

      this.card = this.elements.create('card', { style: cardStyle })
      this.card.on('change', event => {
        const { error } = event
        this.handleStripeErrors(error)
      })
    },
    async addNewCard() {
      try {
        this.isModalLoading = true

        const { paymentMethod } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
        })

        const newCard = {
          id: paymentMethod.id,
          exp_month: paymentMethod.card.exp_month,
          exp_year: paymentMethod.card.exp_year,
          last4: paymentMethod.card.last4,
        }
        
        await this.addCard(newCard)

        if (this.cardsList.length === 1) {
          await this.setCardAsDefault(paymentMethod.id)
        }

        this.$emit('hideCardModal')
      } catch (error) {
        if (error.message) {
          this.handleStripeErrors(error)
        } else {
          this.errorMessage = error.response.data.detail
        }
      } finally {
        this.isModalLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }
  
  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.error-container {
  width: calc(100% - 80px);    width: calc(100% - 80px);
  height: 40px;
  display: flex;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

#card-element {
  width: calc(100% - 80px);
  height: 50px;
  margin-top: 5px;
}

.action-button {
  margin-top: 10px;
}
</style>