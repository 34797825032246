<template>
  <div class="billing-wrapper">
    <div class="b2b-billings-error-message">{{errorMessage}}</div>
    <div class="billing-header">
      <div class="title">Billing</div>
    </div>
    <div class="account-cards">
      <div class="account-cards-info">
        <div class="accounts-card-text">
          <div class="subsection-title">Credit or debit cards</div>
          <div class="account-cards-description">
            The card will be debited on the date the bill is paid. All major credit / debit cards are accepted.
          </div>
        </div>
        <ActionButton
          :width="'151px'"
          :height="'44px'"
          :text="'Add card'"
          @click="showCardModal"
        />
        <NewCardModal
          :isCardModalShown="isCardModalShown"
          @hideCardModal="hideCardModal"
        />
      </div>
      <div class="account-cards-list">
        <Card
          v-for="card in cardsList"
          :key="card.id"
          :cardId="card.id"
          :last4="card.last4"
          :userDefaultCard="currentUser.subscription.default_payment_method"
          :cardExpirationMonth="card.exp_month"
          :cardExpirationYear="card.exp_year"
          :isActionsAvailable="currentUser.subscription.default_payment_method !== card.id"
          @actionMenuItemClick="handleActionMenuClick($event, card.id)"
        />
      </div>
    </div>
    <div class="upcoming-invoice-container" v-if="currentUser.subscription.interval">
      <div class="upcoming-invoice-title">Upcoming invoice</div>
      <div class="current-plan-data">
        <div class="upcoming-invoice-data">
          <div class="upcoming-invoice-field">Date: {{getFormattedBillingDate(upcomingInvoice.period_end)}}</div>
          <div class="upcoming-invoice-field">Value: {{upcomingInvoice.amount_due / 100}}$</div>
          <div class="upcoming-invoice-field">Balance: {{upcomingInvoice.balance / 100}}$</div>
        </div>
        <ActionButton
          :width="'151px'"
          :height="'44px'"
          :text="planBtnTitle"
          :style="{'margin-top': 0}"
          @click="switchPlanConfirmationModal"
        />
        <ActionModal
          v-if="isPlanConfirmationModalShown"
          :width="'440px'"
          :height="'310px'"
          :isModalShown="isPlanConfirmationModalShown"
        >
          <div class="modal-header">
            <div class="modal-title">Switch plan</div>
            <div class="close-modal-btn" @click="switchPlanConfirmationModal"></div>
          </div>
          <div class="modal-content">
            <div class="modal-text">Your plan will be changed. You'll pay {{planModalText}}.</div>
            <div class="modal-buttons">
              <ActionButton
                :text="'Confirm'"
                @click="switchCurrentPlan"
              />
              <ActionButton
                :text="'Cancel'"
                :class="{'red-action-button': true}"
                @click="switchPlanConfirmationModal"
              />
            </div>
          </div>
        </ActionModal>
      </div>
    </div>
    <div class="billings-history-container">
      <div class="billings-history-title">Billing history</div>
      <div class="billings-history-table">
        <div class="billings-history-table-header billings-history-table-row">
          <div class="billings-history-table-field billing-date">
            Date
          </div>
          <div class="billings-history-table-field billing-description">
            Description
          </div>
          <div class="billings-history-table-field billing-amount">
            Amount
          </div>
          <div class="billings-history-table-field billing-status">
            Status
          </div>
          <div class="billings-history-table-field billing-action">
            Action
          </div>
        </div>
        <div class="billing-list">
          <div
            class="billings-history-table-row"
            v-for="record in billingHistory"
            :key="record.id"
          >
            <div class="billings-history-table-field billing-date">
              {{getFormattedBillingDate(record.created)}}
            </div>
            <div class="billings-history-table-field billing-description">
              Payment ({{record.card}})
            </div>
            <div class="billings-history-table-field billing-amount">
              {{'$' + record.amount / 100}}
            </div>
            <div
              class="billings-history-table-field billing-status"
              :class="{'successful-payment': record.status === 'succeeded'}"
            >
              {{record.status}}
            </div>
            <div class="billings-history-table-field billing-action">
              <div class="dowload-receipt-btn" @click="handleReceiptDownloading(record.receipt_url)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-overlay" v-if="isLoading">
      <Spinner
        :width="'35px'"
        :height="'35px'"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import NewCardModal from './NewCardModal.vue';
import ActionButton from '../../common/ActionButton.vue'
import ActionModal from '../../common/ActionModal.vue'
import Spinner from '../../common/Spinner.vue'
import Card from '../../common/cards/Card.vue'

import * as subscriptionsApi from '../../../api/subscriptionsApi'
import { UserSubscriptionStatus } from '../../../utils/constants'

export default {
  components: {
    NewCardModal,
    ActionButton,
    ActionModal,
    Spinner,
    Card,
  },
  data() {
    return {
      billingHistory: [],
      upcomingInvoice: {
        period_end: Math.floor(Date.now() / 1000),
        period_start: Math.floor(Date.now() / 1000),
        amount_due: 0,
        balance: 0,
      },
      alternativePlan: {
        id: '',
        interval: '',
        amount: 0,
        members_count: 0,
      },
      errorMessage: '',
      UserSubscriptionStatus: UserSubscriptionStatus,
      isLoading: true,
      isSubscriptionBtnLoading: false,
      isCardModalShown: false,
      isPlanConfirmationModalShown: false,
    }
  },
  async created() {
    await this.fetchInitialData()
  },
  computed: {
    ...mapState([
      'currentUser',
      'cardsList',
    ]),
    planBtnTitle() {
      if (this.currentUser.subscription.interval === 'month') return 'Switch to yearly'
      if (this.currentUser.subscription.interval === 'year') return 'Switch to monthly'
    },
    planModalText() {
      const alternativePlanValue = this.alternativePlan.amount / 100
      const chargeForAllMembers = alternativePlanValue * this.alternativePlan.members_count

      return `${alternativePlanValue}$/${this.alternativePlan.interval} for each member.
        ${chargeForAllMembers}$ for all members`
    },
  },
  methods: {
    ...mapActions([
      'updateUserLocally',
      'getCardsList',
      'setCardAsDefault',
      'deleteCard',
    ]),
    async fetchInitialData() {
      try {
        await this.getCardsList()
        this.upcomingInvoice = await subscriptionsApi.getUpcomingInvoice()
        this.billingHistory = await subscriptionsApi.getBillingHistory()

        if (this.currentUser.subscription.interval) {
          this.alternativePlan = await subscriptionsApi.getAlternativePlanData()
        }
      } catch (error) {
        this.errorMessage = 'Something went wrong, please, try again...'
      } finally {
        this.isLoading = false
      }
    },
    getFormattedBillingDate(timestamp) {
      const date = new Date(timestamp * 1000)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      const formatted = `${month}/${day}/${year}`
      
      if (formatted === '01/01/1970') {
        return 'mm/dd/yyyy'
      }

      return formatted
    },
    handleReceiptDownloading(url) {
      window.open(url)
    },
    showCardModal() {
      this.isCardModalShown = true
    },
    hideCardModal() {
      this.isCardModalShown = false
    },
    async handleActionMenuClick(evt, cardId) {
      this.isLoading = true

      try {
        if (evt === 'Make default') {
          await this.setCardAsDefault(cardId)
        } else if (evt === 'Delete card') {
          await this.deleteCard(cardId)
        }
      } catch (error) {
        this.errorMessage = 'Something went wrong, please, try again...'
      } finally {
        this.isLoading = false
      }
    },
    switchPlanConfirmationModal() {
      this.isPlanConfirmationModalShown = !this.isPlanConfirmationModalShown
    },
    async switchCurrentPlan() {
      try {
        this.isPlanConfirmationModalShown = false
        this.isLoading = true

        await subscriptionsApi.changeSubscriptionPlan()
        this.alternativePlan = await subscriptionsApi.getAlternativePlanData()

        const subscription = { interval: '' }

        if (this.currentUser.subscription.interval === 'month') {
          subscription.interval = 'year'
        } else if (this.currentUser.subscription.interval === 'year') {
          subscription.interval = 'month'
        }

        await this.updateUserLocally({ subscription })
        this.upcomingInvoice = await subscriptionsApi.getUpcomingInvoice()
        this.billingHistory = await subscriptionsApi.getBillingHistory()
      } catch (error) {
        this.errorMessage = 'Something went wrong, please try again'
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.billing-wrapper {
  width: 100%;
  max-width: 720px;
  height: 600px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: $whiteColor;

  .b2b-billings-error-message {
    width: 100%;
    max-width: 720px;
    height: 20px;
    min-height: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }

  .billing-header {
    width: calc(100% - 60px);
    height: 60px;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $fontColorBlack;
    font-size: $fontSizeModalTitle;
  }

  .inactive-payment {
    width: calc(100% - 60px);
    height: 145px;
    background-color: #FADEDE !important;
    border: 1px dashed red;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }

  .account-payment {
    width: calc(100% - 60px);
    min-height: 145px;
    background-color: #F4F5FC;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 0 24px;
    box-sizing: border-box;

    .payment-title {
      margin-top: 30px;
      color: $fontColorBlack;
    }

    .payment-container {
      margin-top: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title-container {
        width: 429px;

        .title {
          margin-top: 2px;
          color: #535971;
          display: flex;

          .active-subscription {
            color: #40BA40;
          }

          .inactive-subscription {
            color: $redWarningColor
          }
        }
      }

      .action-button {
        margin-top: 0px !important;
      }

      .subscription-action-loading-indicator {
        width: 151px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $appActionColor;
        color: $fontColorWhite;
        border-radius: 4px;

        .spinner-container {
          width: 100%;
          height: calc(100% - 104px);
          display: flex;
          justify-content: center;
          align-items: center;

          .spinner {
            border: 7px solid #FFFFFF;
            border-radius: 50%;
            border-top: 7px solid $appActionColor;
            width: 12px;
            height: 12px;
            animation: spin 2s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        }
      }
    }
  }

  .account-cards {
    width: calc(100% - 60px);
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #F4F5FC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;

    .account-cards-info {
      width: 100%;
      height: 100px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      .accounts-card-text {
        width: 429px;

        .account-cards-description {
          margin-top: 24px;
          color: #535971;
        }
      }

      .action-button {
        align-self: center;
      }
    }

    .account-cards-list {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .card-wrapper {
    margin-top: 12px;
    width: calc(100% - 60px);
    height: 249px;
    background-color: #F4F5FC;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    .card-title {
      margin-left: 24px;
      margin-top: 10px;
    }

    .title-container {
      .title {
        margin-left: 24px;
        margin-top: 2px;
        width: 95%;
        height: 44px;
        color: #535971;
        display: flex;
      }
    }
  }

  .upcoming-invoice-container {
    width: calc(100% - 60px);
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    box-sizing: border-box;
    background-color: #F4F5FC;
    border-radius: 4px;

    .upcoming-invoice-title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: $fontSizeModalTitle;
      margin-bottom: 20px;
    }

    .current-plan-data {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .upcoming-invoice-data {
        height: 100%;
        display: flex;
        flex-direction: column;

        .upcoming-invoice-field {
          width: 100%;
          font-size: $fontSizeLarger;
        }
      }

      .modal-header {
        width: calc(100% - 80px);
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-title {
          font-size: $fontSizeModalTitle;
        }

        .close-modal-btn {
          width: 24px;
          height: 24px;
          background-size: 14px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../../../assets/icons/close-icon.svg');
          cursor: pointer;
        }
      }

      .modal-content {
        width: calc(100% - 80px);
        height: 248px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .modal-text {
          margin-top: 20px;
          font-size: $fontSizeLarger;
        }

        .modal-buttons {
          height: 44px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .action-button {
            margin-top: 0px;
            width: 150px;
            height: 44px;
          }

          .red-action-button {
            background-color: $redWarningColor;

            &:hover {
              background-color: $hoveredRedWarninngColor;
            }
          }
        }
      }
    }
  }

  .billings-history-container {
    width: calc(100% - 60px);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .billings-history-title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: $fontSizeModalTitle;
      margin-bottom: 20px;
    }

    .billings-history-table {
      width: 100%;
      border: 1px solid #F4F5FC;
      border-radius: 4px;

      .billings-history-table-header {
        background-color: #F4F5FC;
        color: #888888;
      }

      .billings-history-table-row {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #F4F5FC;
        color: #535971;

        .billings-history-table-field {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .billing-date {
          width: 150px;
        }

        .billing-description {
          width: 230px;
        }

        .billing-amount {
          width: 100px;
          justify-content: center;
        }

        .billing-status {
          width: 105px;
          justify-content: center;
        }

        .successful-payment {
          color: #40BA40;
        }

        .billing-action {
          width: 48px;
          justify-content: center;

          .dowload-receipt-btn {
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../../assets/icons/download-icon.svg');
            cursor: pointer;

            &:hover {
              background-image: url('../../../assets/icons/download-active-icon.svg');
            }
          }
        }
      }

      .billing-list {
        width: 100%;
        padding-bottom: 10px;
      }
    }
  }

  .loading-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
  }
}
</style>
